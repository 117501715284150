import React from "react";
import Box from "@mui/material/Box";
import LoadingButton from "../../Buttons/LoadingButton";
import {
  BUTTON_DOWNLOAD,
  BUTTON_SEE_PUBLICATION_ON,
  I_NEED_HELP,
  BUTTON_UPLOAD_INSTAGRAM,
  INSTAGRAM,
  TIKTOK,
  REVISION_OPTION_GOOD,
  REVISION_OPTION_REGULAR,
  REVISION_OPTION_BAD,
} from "../../../../locales/keysTranslations";
import {
  IMAGE_FORMATS,
  LINK_TO_WHATSAPP_SUPPORT_CONTENT,
  POST_STATUS_NEGATIVE_REVIEW,
  POST_STATUS_PENDING_APPROVAL,
  POST_STATUS_PENDING_CONTENT,
  POST_STATUS_SENT,
  ROLES_SHOPS,
  VIDEO_FORMATS,
} from "../../../../utils/constants";
import { useTranslationApp } from "../../../../lib/i18next";
import { detectPlatform, openLink } from "../../../../utils/urls";
import DownloadIcon from "@mui/icons-material/Download";
import { getRole } from "../../../../actions/getters";
import { useDispatchApp } from "../../../../lib/redux";
import Button from "../../Buttons/Button";
import { formatURL } from "../../../../utils/formats";
import ButtonDoAmbassador from "../../Buttons/ButtonDoAmbassador";
import ButtonMetaAction from "../../Buttons/ButtonMetaAction";
import ButtonTikTokAction from "../../Buttons/ButtonTikTokAction";
import LinkText from "../../Texts/LinkText";
import ButtonMenu from "../../Buttons/ButtonMenu";
import { startGetAwsDownloadUrl } from "../../../../actions/shops";

const ModalMediaPublicationButtons = ({
  data = {},
  isAdminUser,
  isFetching,
  setIsFetching,
  url,
  extension,
  setFrom,
  setModalUploadSocialNetworkOpen,
  onClosePrevComponent,
  onOpenModalReviewStatusChange,
  onOpenModalSendRevisionStatusChange,
}) => {
  const dispatch = useDispatchApp();
  const role = dispatch(getRole());
  const { t } = useTranslationApp();

  const disabledForReviewOrder =
    data.from === "order" &&
    (data.postStatus === POST_STATUS_PENDING_CONTENT ||
      data.postStatus === POST_STATUS_NEGATIVE_REVIEW);

  const isOrderInReviewStatus =
    data.from === "order" && data.postStatus === POST_STATUS_SENT;
  const platformPost = detectPlatform(data.verifyPostLink || "");

  const disabledForMainActions = disabledForReviewOrder;

  const showActionRevision = data.postStatus === POST_STATUS_PENDING_APPROVAL;

  const historyLength = data?.history?.length || 0;

  const showButtonRevision =
    (data.from === "order" && historyLength < 1) ||
    (data.from === "applicant" && historyLength < 3);

  const showButtonDownload =
    url && data.postStatus !== POST_STATUS_PENDING_APPROVAL;
  const showButtonSocialNetworkPostLink =
    platformPost !== "asset" &&
    data.postStatus !== POST_STATUS_PENDING_APPROVAL;
  const showButtonAmbassador =
    isAdminUser && data.postStatus !== POST_STATUS_PENDING_APPROVAL;
  const showButtonUploadInstagram =
    (VIDEO_FORMATS.includes(extension) || IMAGE_FORMATS.includes(extension)) &&
    ROLES_SHOPS.includes(role) &&
    data.postStatus !== POST_STATUS_PENDING_APPROVAL;
  const showButtonUploadTikTok =
    VIDEO_FORMATS.includes(extension) &&
    ROLES_SHOPS.includes(role) &&
    data.postStatus !== POST_STATUS_PENDING_APPROVAL;

  const namePlatform = showButtonSocialNetworkPostLink ? t(platformPost) : "";

  if (disabledForMainActions) return null;

  return (
    <Box
      sx={{
        maxWidth: "100%",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        gap: 1,
        flexShrink: 0,
      }}
    >
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          flexDirection: {
            xs: "column",
            sm: "row",
          },
          gap: 1,
        }}
      >
        {showActionRevision && (
          <Box
            sx={{
              flex: 1,
              maxWidth: { xs: "100%", sm: 300 },
            }}
          >
            {showButtonRevision ? (
              <ButtonMenu
                color="primary"
                fullWidth
                textButton={I_NEED_HELP}
                options={[
                  {
                    label: REVISION_OPTION_GOOD,
                    onClick: () => openLink(LINK_TO_WHATSAPP_SUPPORT_CONTENT),
                  },
                  {
                    label: REVISION_OPTION_REGULAR,
                    onClick: () => openLink(LINK_TO_WHATSAPP_SUPPORT_CONTENT),
                  },
                  {
                    label: REVISION_OPTION_BAD,
                    onClick: () => onOpenModalSendRevisionStatusChange(true),
                  },
                ]}
              />
            ) : (
              <LoadingButton
                color="primary"
                sx={{
                  width: "100%",
                  height: "100%",
                }}
                onClick={() => openLink(LINK_TO_WHATSAPP_SUPPORT_CONTENT)}
              >
                {t(I_NEED_HELP)}
              </LoadingButton>
            )}
          </Box>
        )}
        {showButtonDownload && (
          <Box
            sx={{
              flex: 1,
              maxWidth: { xs: "100%", sm: 300 },
            }}
          >
            <LoadingButton
              loading={isFetching}
              startIcon={<DownloadIcon />}
              color="primary"
              sx={{
                backgroundColor: isFetching
                  ? "gray !important"
                  : "primary.main",
                width: "100%",
                height: "100%",
              }}
              onClick={async () => {
                if (isOrderInReviewStatus) {
                  onOpenModalReviewStatusChange(true);
                  return;
                }
                setIsFetching(true);
                await dispatch(startGetAwsDownloadUrl(url));
                setIsFetching(false);
              }}
            >
              {t(BUTTON_DOWNLOAD)}
            </LoadingButton>
          </Box>
        )}
        {showButtonSocialNetworkPostLink && (
          <LinkText
            href={isOrderInReviewStatus ? "#" : formatURL(data.verifyPostLink)}
            target={isOrderInReviewStatus ? "" : "_blank"}
            sx={{
              flex: 1,
              maxWidth: { xs: "100%", sm: 300 },
              textDecoration: "none",
            }}
            onClick={() => {
              if (isOrderInReviewStatus) {
                onOpenModalReviewStatusChange(true);
                return;
              }
            }}
          >
            <Button
              color="primary"
              sx={{
                width: "100%",
              }}
            >
              {t(BUTTON_SEE_PUBLICATION_ON, {
                value: namePlatform,
              })}
            </Button>
          </LinkText>
        )}
        {showButtonAmbassador && (
          <ButtonDoAmbassador
            isExternalBlocked={isOrderInReviewStatus}
            onCallbackExternalBlocked={() =>
              onOpenModalReviewStatusChange(true)
            }
            userID={data.userID}
            color="primary"
            sxContainer={{
              flex: 1,
              width: "100%",
              maxWidth: { xs: "100%", sm: 300 },
              my: 0,
              justifyContent: "flex-start",
            }}
            sxButton={{
              width: "100%",
            }}
            onClosePrevComponent={onClosePrevComponent}
          />
        )}
      </Box>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          flexDirection: {
            xs: "column",
            sm: "row",
          },
          gap: 1,
        }}
      >
        {showButtonUploadInstagram && (
          <Box sx={{ flex: 1, maxWidth: { xs: "100%", sm: 300 } }}>
            <ButtonMetaAction
              text={BUTTON_UPLOAD_INSTAGRAM}
              prevBlockClick={isOrderInReviewStatus}
              onPrevBlockClick={() => onOpenModalReviewStatusChange(true)}
              onClick={() => {
                setFrom(INSTAGRAM);
                setModalUploadSocialNetworkOpen(true);
              }}
            />
          </Box>
        )}
        {showButtonUploadTikTok && (
          <Box sx={{ flex: 1, maxWidth: { xs: "100%", sm: 300 } }}>
            <ButtonTikTokAction
              prevBlockClick={isOrderInReviewStatus}
              onPrevBlockClick={() => onOpenModalReviewStatusChange(true)}
              onClick={() => {
                setFrom(TIKTOK);
                setModalUploadSocialNetworkOpen(true);
              }}
            />
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default ModalMediaPublicationButtons;
