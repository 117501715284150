import React, { useState } from "react";
import { useDispatchApp, useSelectorApp } from "../../../../../lib/redux";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableHead from "@mui/material/TableHead";
import Box from "@mui/material/Box";
import { DEFAULT_TABLE_PAGES } from "../../../../../utils/constants";
import SocialNetworkCell from "../../../../Common/Influencer/SocialNetworkCell";
import { getAnalyticsByUser } from "../../../../../utils/socialNetworks";
import TableContainer from "../../../../Common/Table/TableContainer";
import TableCell from "../../../../Common/Table/TableCell";
import NameInfluencerCell from "../../../../Common/Influencer/NameInfluencerCell";
import { useTranslationApp } from "../../../../../lib/i18next";
import {
  COLUMN_NAME_AUDIENCE,
  COLUMN_NAME_ENGAGEMENT,
  COLUMN_NAME_CREATOR_NAME,
  CREATORS_TABLE_COLUMN_SCORE,
  COLUMN_NAME_CITY,
  COLUMN_NAME_CATEGORIES,
  COLUMN_NAME_ACTION,
  EMPTY_INVITATIONS_APPLICANTS,
} from "../../../../../locales/keysTranslations";
import Rating from "../../../../Common/Forms/Rating/Rating";
import {
  capitalizeFirstLetter,
  getCityInfluencer,
} from "../../../../../utils/formats";
import InfluencerCategories from "../../../../Common/Influencer/InfluencerCategories";
import { getInfluencerIsHiddenFromTables } from "../../../../../actions/getters";
import ActionsApplicantButtons from "../Applicants/ActionsApplicantButtons";
import InitChatButton from "../../../../Common/Chat/InitChatButton";
import { isFilteringInfluencers } from "../../../../../services/shops";
import useCreatorsFilters from "../../../../../hooks/useCreatorsFilters";

const InvitationsCampaignsTable = ({
  data,
  filters = {},
  onAcceptApplicant,
  onRejectedApplicant,
  statusCampaign,
  campaignID,
  onOpenChatInfluencer,
}) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage] = useState(DEFAULT_TABLE_PAGES);

  const { t } = useTranslationApp();
  const { onInfluencerPassFilter } = useCreatorsFilters();

  const {
    topRatedCategorySelected,
    newCategorySelected,
    favoritesCategorySelected,
    bodyBuild,
    clothingStyle,
    distinguishingFeatures,
    contentStyle,
    categoriesSelected,
    citySelected,
    genderSelected,
    rangeEngagement,
    rangeFollowers,
    searchText,
  } = filters;

  const isFiltering = isFilteringInfluencers({
    topRatedCategorySelected,
    newCategorySelected,
    favoritesCategorySelected,
    bodyBuild,
    clothingStyle,
    distinguishingFeatures,
    contentStyle,
    categoriesSelected,
    citySelected,
    genderSelected,
    rangeEngagement,
    rangeFollowers,
    searchText,
  });

  const influencers = useSelectorApp((state) => state.influencers.influencers);
  const applicants = useSelectorApp((state) => state.campaigns.applicants);
  const dispatch = useDispatchApp();

  const filteredInfluencers = () => {
    const applicants = Object.keys(data);
    if (applicants.length === 0) return {};

    const filtered = {};

    for (let applicantKey of applicants) {
      const applicant = data[applicantKey];
      const influencer = influencers[applicant.userID] || {};

      const isHidden = dispatch(
        getInfluencerIsHiddenFromTables({ userID: applicant.userID })
      );

      if (isHidden) continue;

      const passFilter = onInfluencerPassFilter({
        influencer,
        filters,
        userID: applicant.userID,
      });

      if (!passFilter) continue;

      filtered[applicantKey] = applicant;
    }
    return filtered;
  };

  const keysSorted = Object.keys(filteredInfluencers()).sort((a, b) => {
    if (data[a]?.applicantID && !data[b]?.applicantID) return -1;
    return 1;
  });

  const onChangePage = (newPage) => {
    setPage(newPage);
  };
  return (
    <Box
      sx={{
        width: "100%",
      }}
    >
      <TableContainer
        page={page}
        onPageChange={onChangePage}
        rowsPerPage={rowsPerPage}
        keys={keysSorted}
        emptyText={EMPTY_INVITATIONS_APPLICANTS}
        loading={false}
        isFiltering={isFiltering}
        sx={{ maxHeight: 500, overflow: "auto" }}
      >
        <TableHead>
          <TableRow>
            <TableCell align="center" isHeader={true}>
              {t(COLUMN_NAME_CREATOR_NAME)}
            </TableCell>
            <TableCell align="center" isHeader={true}>
              {t(COLUMN_NAME_CITY)}
            </TableCell>
            <TableCell align="center" isHeader={true}>
              {t(COLUMN_NAME_AUDIENCE)}
            </TableCell>
            <TableCell align="center" isHeader={true}>
              {t(COLUMN_NAME_ENGAGEMENT)}
            </TableCell>
            <TableCell align="center" isHeader={true}>
              {t(COLUMN_NAME_CATEGORIES)}
            </TableCell>
            <TableCell align="center" isHeader={true}>
              {t(CREATORS_TABLE_COLUMN_SCORE)}
            </TableCell>
            <TableCell align="center" isHeader={true} isSticky={true}>
              {t(COLUMN_NAME_ACTION)}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {(rowsPerPage > 0
            ? keysSorted.slice(
                page * rowsPerPage,
                page * rowsPerPage + rowsPerPage
              )
            : keysSorted
          ).map((key, index) => {
            const invite = data[key];
            const influencer = influencers[invite.userID];
            const applicant = applicants[invite.applicantID];

            return (
              <TableRow key={index}>
                <TableCell align="center" indexRow={index}>
                  <NameInfluencerCell
                    userID={invite.userID}
                    data={influencer}
                    showButton={false}
                  />
                </TableCell>
                <TableCell
                  align="center"
                  indexRow={index}
                  sx={{ minWidth: 190 }}
                >
                  {capitalizeFirstLetter(getCityInfluencer(influencer))}
                </TableCell>
                <TableCell align="center" indexRow={index}>
                  <SocialNetworkCell
                    platform={influencer?.mainPlatform}
                    data={getAnalyticsByUser(influencer)}
                    metric="audience"
                  />
                </TableCell>
                <TableCell align="center" indexRow={index}>
                  <SocialNetworkCell
                    platform={influencer?.mainPlatform}
                    data={getAnalyticsByUser(influencer)}
                    metric="engagement"
                    sign="%"
                  />
                </TableCell>
                <TableCell
                  align="center"
                  indexRow={index}
                  sx={{ maxWidth: 200 }}
                >
                  <InfluencerCategories
                    influencer={influencer}
                    categoriesSelected={categoriesSelected}
                  />
                </TableCell>
                <TableCell align="center" indexRow={index}>
                  <Rating
                    value={influencer?.score ?? 0}
                    readOnly={true}
                    precision={0.5}
                  />
                </TableCell>
                <TableCell align="center" indexRow={index} isSticky={true}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      gap: 1,
                    }}
                  >
                    {invite.applicantID && (
                      <ActionsApplicantButtons
                        onAcceptApplicant={onAcceptApplicant}
                        onRejectedApplicant={onRejectedApplicant}
                        statusCampaign={statusCampaign}
                        data={applicant}
                        applicantID={invite.applicantID}
                      />
                    )}
                    <InitChatButton
                      onClick={() => onOpenChatInfluencer(invite?.userID)}
                    />
                  </Box>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </TableContainer>
    </Box>
  );
};

export default InvitationsCampaignsTable;
